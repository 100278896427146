import React from 'react';

import {
    container,
    button,
    noPromotions,
    details,
    promotionItem,
} from './cart-summary.module.scss';
import { useCart } from '../../hooks/use-cart';
import useTranslations from '../../hooks/use-translations';

import DataHeading from '../molecules/data-heading';
import PromotionMessagesList from '../molecules/promotion-messages-list';
import OrderDetails from '../molecules/order-details';
import SummaryDisclaimer, { ISummaryDisclaimerProps } from '../molecules/summary-disclaimer';
import Button from '../atoms/button';

interface ICartSummaryProps {
    className?: string;
    buttonLink?: string;
    summaryDisclaimerProps?: Pick<ISummaryDisclaimerProps, 'firstInfo' | 'secondInfo'>;
}

const CartSummary: React.FC<ICartSummaryProps> = ({
    className = '',
    buttonLink,
    summaryDisclaimerProps,
}) => {
    const t = useTranslations('CartSummary');
    const cartState = useCart();
    const promotions = cartState.data?.promotions.filter(
        (promotion) => promotion.messages.length > 0
    );

    const noPromotionsClass = !promotions?.length ? noPromotions : '';

    return (
        <div className={`${container} ${className} ${noPromotionsClass}`}>
            <DataHeading firstColumn={t.title} />
            {promotions && promotions.length > 0 && (
                <PromotionMessagesList promotions={promotions} itemClassName={promotionItem} />
            )}
            <OrderDetails
                className={details}
                orderPrice={cartState.data?.finalPrice}
                deliveryFinalPrice={cartState.data?.delivery?.finalPrice}
                deliveryStepIsComplete={false}
            />
            <SummaryDisclaimer {...summaryDisclaimerProps}>
                {buttonLink && (
                    <Button to={buttonLink} as="link" className={button}>
                        {t.button}
                    </Button>
                )}
            </SummaryDisclaimer>
        </div>
    );
};

export default CartSummary;
