import React from 'react';

import {
    grid,
    container,
    list,
    loading,
    empty,
    loaderBox,
    emptyBox,
    cartBox,
    summaryBox,
    cartDisclaimer,
} from './cart.module.scss';
import { TOrderSection } from '../../templates/order';
import { useCart } from '../../hooks/use-cart';
import { useUser } from '../../hooks/use-user';

import DataHeading from '../molecules/data-heading';
import CartList from '../molecules/cart-list';
import Section from '../hoc/section';
import CartItem from '../molecules/cart-item';
import Loader from '../atoms/loader';
import Title from '../atoms/title';
import Button from '../atoms/button';
import useTranslations from '../../hooks/use-translations';
import CartSummary from '../organisms/cart-summary';

export interface ISectionCartProps {
    section: TOrderSection;
}

export default function Cart({ section }: ISectionCartProps) {
    const t = useTranslations('Cart');
    const {
        sectionId,
        css,
        style,
        settings: { availablePaths },
        content: { texts },
    } = section;
    const [firstInfo, secondInfo] = texts;
    const { items, handleCartItemQuantityChange, handleRemoveCartItem, isDisabled } = useCart();
    const { data: user } = useUser();

    const buttonLink = !!user ? availablePaths.order : availablePaths['order-login'];

    const cartIsLoading = isDisabled && !items.length;
    const cartIsEmpty = !isDisabled && !items.length;

    return (
        <Section sectionId={sectionId} classes={{ container: grid }} css={css} style={style}>
            <div
                className={`${container} ${cartIsLoading ? loading : ''} ${
                    cartIsEmpty ? empty : ''
                }`}
            >
                <div className={cartBox}>
                    <DataHeading firstColumn={<h2>{t.headingColumn.first}</h2>} />
                    <CartList
                        className={list}
                        items={items}
                        cartItemElement={
                            <CartItem
                                onQuantityChange={handleCartItemQuantityChange}
                                onRemove={handleRemoveCartItem}
                                isDisabled={isDisabled}
                            />
                        }
                    />
                    <p className={cartDisclaimer}>{t.disclaimer}</p>
                </div>
                <CartSummary
                    className={summaryBox}
                    buttonLink={buttonLink}
                    summaryDisclaimerProps={{ firstInfo, secondInfo }}
                />
                <div className={emptyBox}>
                    <Title>{t.empty}</Title>
                    <Button as="link" to="/">
                        {t.button}
                    </Button>
                </div>
                <div className={loaderBox}>
                    <Loader />
                </div>
            </div>
        </Section>
    );
}
